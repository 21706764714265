import { combineReducers } from "redux";
import authReducer from "./authReducer";

const appReducer = combineReducers({
	authReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "LOGOUT_CURRENT_USER") {
		const { authReducer } = state;
		state = { authReducer };
	}
	return appReducer(state, action);
};

export default rootReducer;
