import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { isMobile } from "react-device-detect";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<GoogleOAuthProvider clientId="1092087324176-ak1qbaca2qprd2401kn417n53npj8s3m.apps.googleusercontent.com">
		{!isMobile ? (
			<App />
		) : (
			<div className="modileView">Please use this link in desktop</div>
		)}
	</GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
