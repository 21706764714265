const initialState = {
	isTokenVerified: false,
	isAuthenticated: false,
	showAlert: false,
	isTenantInfoFetched: false,
	isUsrSessionChecked: false,
	alertProperties: {
		Text: "",
		Type: "info",
	},
	tenantId: null,
	user: {},
	landingPage: "/home",
	isTenantFetchFailed: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "SET_CURRENT_USER":
			return {
				...state,
				isTokenVerified: true,
			};
		default:
			return state;
	}
}
