import React, { Component, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useNavigate,
} from "react-router-dom";
import store from "./store";
import { SnackbarProvider } from "notistack";
import posthog from "posthog-js";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";

const Login = lazy(() => import("./pages/Login"));
const Verify = lazy(() => import("./pages/EmailConfirmation"));
const OTPValidation = lazy(() => import("./pages/OTPValidation"));
const Layout = lazy(() => import("./pages/Layout"));
const Product = lazy(() => import("./pages/Product"));
const NotFound = lazy(() => import("./pages/NotFound"));

posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
	api_host: process.env.REACT_APP_POSTHOG_API_URL,
});

function PrivateRoute({ children }) {
	const navigate = useNavigate();
	let authKey = localStorage.getItem("auth_key");
	let info = authKey ? jwt_decode(authKey) : "";
	if (!info?.user_id && info?.hd !== "impactanalytics.co") {
		// not logged in so redirect to login page with the return url
		return <Navigate to="/not-found" />;
	}
	// code for token expiry check
	// if (info?.end_date) {
	// 	let current = new moment(new Date(), "M/D/YYYY h:mm")
	// 		.tz(moment.tz.guess())
	// 		.format();
	// 	let end = new moment(info?.end_date);
	// 	let diff = end.diff(current, "hours");
	// 	if (diff <= 0) {
	// 		localStorage.removeItem("auth_key");
	// 		navigate("/login");
	// 	}
	// }
	// authorized so return child components
	return children;
}

function App() {
	return (
		<React.Fragment>
			<Provider store={store}>
				<SnackbarProvider
					maxSnack={3}
					autoHideDuration={5000}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
				>
					<Router>
						<div className="App">
							<Suspense fallback={<div> </div>}>
								<Routes>
									<Route exact path="/" element={<Navigate to="/login" />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/verification" element={<Verify />} />
									<Route
										exact
										path="/otp-validation"
										element={<OTPValidation />}
									/>
									<Route
										exact
										path="/:client/home"
										element={
											<PrivateRoute>
												<Layout />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/assort-smart/*"
										element={
											<PrivateRoute>
												<Product product={"assort-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/plan-smart/*"
										element={
											<PrivateRoute>
												<Product product={"plan-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/price-smart/*"
										element={
											<PrivateRoute>
												<Product product={"price-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/inventory-smart/*"
										element={
											<PrivateRoute>
												<Product product={"inventory-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/track-smart/*"
										element={
											<PrivateRoute>
												<Product product={"track-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/cluster-smart/*"
										element={
											<PrivateRoute>
												<Product product={"cluster-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/promo-smart/*"
										element={
											<PrivateRoute>
												<Product product={"promo-smart"} />
											</PrivateRoute>
										}
									/>
									<Route
										exact
										path="/:client/ada-smart/*"
										element={
											<PrivateRoute>
												<Product product={"ada-visual"} />
											</PrivateRoute>
										}
									/>
									<Route exact path="/not-found" element={<NotFound />} />
								</Routes>
							</Suspense>
						</div>
					</Router>
				</SnackbarProvider>
			</Provider>
		</React.Fragment>
	);
}

export default App;
